import Fila20 from "../assets/bibTeX/ab_fila_20.txt";
import RecSys20 from "../assets/bibTeX/ab_RecSys_20.txt";
import Acss19 from "../assets/bibTeX/ab_acss.txt";
import Frontiers23 from "../assets/bibTeX/frontiers_2023.txt";
import UMAPDC23 from "../assets/bibTeX/umap-dc-23.txt";
import FairUMAP23 from "../assets/bibTeX/fair-umap23.txt";
import FAcctRec23 from "../assets/bibTeX/fAccTRec_23.txt";
import PATCH24 from "../assets/bibTeX/ab_patch24.txt";

import FairUMAP23Paper from "../assets/papers/fairumap-23.pdf";
import UMAPDCPaper from "../assets/papers/umapdc-23.pdf";
import FILA20Paper from "../assets/papers/fila-2020.pdf";
import RecSys20Paper from "../assets/papers/recSys20.pdf";
import PATCH24Paper from "../assets/papers/PATCH_2024.pdf";

export const PublicationsData = [
  {
    authorNames:
      "<i style=\"text-decoration: underline\">Ashmi Banerjee</i>, Tunar" +
      " Mahmudov, Wolfgang Wörndl",
    paperTitle:
      "Green Destination Recommender: \n" +
      "A Web Application to Encourage Responsible City Trip Recommendations\n",
    paperUrl: PATCH24Paper,
    bibTeX: PATCH24,
    venue: [
      {
        name: "In Proceedings of the 15th Workshop Workshop on Personalized Access\n" +
          "to Cultural Heritage" +
          "co-located with the ACM UMAP 2024\n" +
          "The 32nd ACM Conference on User Modeling, Adaptation and Personalization,",
        url: "https://dl.acm.org/doi/10.1145/3631700.3664909",
        text: " July 1-4, 2014, Cagliari, Italy."
      }
    ]
  },

  {
    authorNames:
      "<i style=\"text-decoration: underline\">Ashmi Banerjee</i>, Paromita" +
      " Banik, Wolfgang Wörndl",
    paperTitle:
      "Towards Individual and Multistakeholder Fairness in Tourism Recommender Systems\n",
    paperUrl: "https://arxiv.org/pdf/2309.02052.pdf",
    bibTeX: FAcctRec23,
    venue: [
      {
        name: "In Proceedings of the 6th FAccTRec Workshop on Responsible Recommendation (FAccTRec'23) co-located with the 16th ACM Conference on Recommender Systems (RecSys),",
        url: "https://doi.org/10.48550/arXiv.2309.02052",
        text: " September 18-22, 2023, Singapore."
      }
    ]
  },
  {
    authorNames:
      "Paromita Banik, <i style=\"text-decoration: underline\">Ashmi Banerjee</i>, Wolfgang Wörndl",
    paperTitle:
      "Understanding User Perspectives on Sustainability and Fairness\n" +
      "in Tourism Recommender Systems",
    paperUrl: FairUMAP23Paper,
    bibTeX: FairUMAP23,
    venue: [
      {
        name: "UMAP'23 Adjunct: Adjunct Proceedings of the 31st ACM Conference on User Modeling, Adaptation and Personalization (UMAP'23),",
        url: "https://doi.org/10.1145/3563359.3597442",
        text: " Limassol, Cyprus, June 2023",
      },
    ],
  },
  {
    authorNames: '<i style="text-decoration: underline">Ashmi Banerjee</i>',
    paperTitle:
      "Fairness and Sustainability in Multistakeholder Tourism Recommender Systems",
    paperUrl: UMAPDCPaper,
    bibTeX: UMAPDC23,
    venue: [
      {
        name: "Proceedings of the 31st ACM Conference on User Modeling, Adaptation and Personalization (UMAP'23),",
        url: "https://doi.org/10.1145/3565472.3595607",
        text: " Limassol, Cyprus, June 2023",
      },
    ],
  },
  {
    authorNames:
      '<i style="text-decoration: underline">Ashmi Banerjee</i>, Paromita Banik, Wolfgang Wörndl',
    paperTitle:
      "A Review on Individual and Multistakeholder\n" +
      "Fairness in Tourism Recommender Systems,",
    paperUrl:
      "https://www.frontiersin.org/articles/10.3389/fdata.2023.1168692/full",
    bibTeX: Frontiers23,
    venue: [
      {
        name: "Frontiers in Big Data, Volume 6, pages 41",
        url: "https://www.frontiersin.org/articles/10.3389/fdata.2023.1168692/full",
        text: "doi: 10.3389/fdata.2023.1168692",
      },
    ],
  },
  {
    authorNames:
      '<i style="text-decoration: underline">Ashmi Banerjee</i>, Gourab K Patro, Linus W. Dietz, Abhijnan Chakraborty',
    paperTitle:
      "Analyzing ‘Near Me’ Services: Potential for Exposure Bias in Location-based Retrieval",
    paperUrl: FILA20Paper,
    bibTeX: Fila20,
    venue: [
      {
        name: "International Workshop on Fair and Interpretable Learning Algorithms (FILA 2020)",
        url: "https://fila-workshop.github.io/",
        text: "in conjunction with the IEEE International Conference on Big Data (IEEE BigData 2020)",
      },
    ],
  },
  {
    authorNames:
      'Gourab K Patro, Abhijnan Chakraborty, <i style="text-decoration: underline">Ashmi Banerjee</i>, Niloy Ganguly',
    paperTitle:
      "Towards Sustainability and Safety: Designing Local Recommendations for Post-pandemic World",
    paperUrl: RecSys20Paper,
    bibTeX: RecSys20,
    venue: [
      {
        name: "14th ACM Conference on Recommender Systems (RecSys 2020)",
        url: "https://recsys.acm.org/recsys20/",
        text: "",
      },
    ],
  },
  {
    authorNames:
      'Anindita Kundu, Shaunak Mukherjee, <i style="text-decoration: underline">Ashmi Banerjee</i>, Subhashis Majumder',
    paperTitle:
      "End-User Position Driven Small Base Station Placement for Indoor Communication",
    paperUrl: "https://link.springer.com/chapter/10.1007/978-981-13-8962-7_2",
    bibTeX: Acss19,
    venue: [
      {
        name: "6th International Doctoral Symposium on Applied Computation" +
          " and Security Systems (ACSS-2019)",
        url: "http://www.acss.cucse.org/",
        text: "",
      },
    ],
  },
];
